<template>
    <div>
        <Report module="Manutenção" name="Ordem de Serviço" urlGet="/api/v1/maintenance/report/report-order-service"
            :header="header" :filters="filters" />
    </div>
</template>

<script>

import Report from "@nixweb/nixloc-ui/src/component/report/Report";

export default {
    name: "ReportOrderService",
    components: {
        Report
    },
    data() {
        return {
            header: [
                {
                    title: "Número",
                    field: "number",
                    fieldOrderBy: "Number",
                    show: true,
                    initialOrderBy: true,
                },
                {
                    title: "Data Cadastro",
                    field: "registeredDateStr",
                    fieldOrderBy: "RegisteredDate",
                    show: true,
                },
                {
                    title: "Identificação",
                    field: "identification",
                    fieldOrderBy: "Identification",
                    show: true,
                },
                {
                    title: "Cliente",
                    field: "customerName",
                    fieldOrderBy: "CustomerName",
                    show: true,
                },
                {
                    title: "Fornecedor",
                    field: "supplierName",
                    fieldOrderBy: "SupplierName",
                    show: true,
                },
                {
                    title: "Tipo",
                    field: "typeMaintenanceName",
                    fieldOrderBy: "TypeMaintenanceName",
                    show: false,
                },
                {
                    title: "R$ Cliente",
                    field: "valueCustomerStr",
                    fieldOrderBy: "ValueCustomer",
                    show: true,
                },
                {
                    title: "R$ Fornecedor",
                    field: "valueSupplierStr",
                    fieldOrderBy: "ValueSupplier",
                    show: true,
                },
            ],
            filters: [
                {
                    type: "date",
                    title: "Data Cadastro",
                    field: "RegisteredDate",
                    value: "",
                    required: true,
                },
                {
                    type: "multiOptions",
                    title: "Cliente",
                    field: "CustomerName",
                    url: "/api/v1/crm/customer/select-all-without-document",
                    propsParams: { isCustomer: true, isSupplier: false },
                    value: "",
                },
                {
                    type: "multiOptions",
                    title: "Fornecedor",
                    field: "SupplierName",
                    url: "/api/v1/crm/customer/select-all-without-document",
                    propsParams: { isCustomer: false, isSupplier: true },
                    value: "",
                },
                {
                    type: "options",
                    title: "Tipo",
                    field: "TypeMaintenanceName",
                    options: [
                        { text: "Corretiva", value: "Corretiva" },
                        { text: "Preventiva", value: "Preventiva" },
                    ],
                    value: [],
                },
                {
                    type: "decimal",
                    title: "Valor Cliente",
                    field: "ValueCustomer",
                    valueStart: 0,
                    valueEnd: 0,
                },
                {
                    type: "decimal",
                    title: "Valor Fornecedor",
                    field: "ValueSupplier",
                    valueStart: 0,
                    valueEnd: 0,
                },
            ],
        }
    }
}


</script>